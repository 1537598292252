.social-social {
  cursor: pointer;
  display: flex;
  /* padding: 8px; */
  position: relative;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.social-social:hover {
  transform: scale(1.3);
}
.social-insider {
  width: 43px;
  height: 43px;
}


@media(max-width: 767px) {
  .social-social {
    padding:5px;
  }
}
