

.slideshow-container {
  position: relative;
  width: 50%;
  overflow:hidden

}

.mySlides {
  display: none;
  overflow:hidden;
  
}

.mySlides.active {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: block;
  overflow:hidden
}



/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration:5s;
  animation-timing-function: cubic-bezier(1, 0.34, 0.3, 0.86);
  animation-delay:0ms;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    left:0px;
  
  }
  to {
    left:10px
  }
}
