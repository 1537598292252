.highlight-highlight {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.highlight-text {
  font-size: 18px;
  max-width: 535px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 27px;
}
.highlight-text1 {
  max-width: 535px;
  font-family: Poppins;
  line-height: 24px;
}
@media(max-width: 767px) {
  .highlight-text {
    font-size: 16px;
    text-align: center;
    line-height: 24px;
  }
}
