.footer-footer {
  gap: var(--dl-space-space-threeunits);
  color: white;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  background-color: #000000;
}
.footer-content {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.footer-information {
  gap: var(--dl-space-space-unit);
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.footer-heading {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 265px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.footer-pasted-image {
  width: 150px;
}
.footer-text {
  font-size: 14px;
  font-family: Poppins;
  line-height: 21px;
}
.footer-socials {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 265px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.footer-link {
  display: contents;
}
.footer-component {
  text-decoration: none;
}
.footer-links {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  z-index: 15;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.footer-column {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.footer-header {
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 30px;
}
.footer-link1 {
  color: rgb(196, 196, 196);
  cursor: pointer;
  font-size: 14px;
  transition: 0.3s;
  line-height: 21px;
}
.footer-link1:hover {
  color: rgba(196, 196, 196, 0.5);
}
.footer-link2 {
  color: rgb(196, 196, 196);
  cursor: pointer;
  font-size: 14px;
  transition: 0.3s;
  line-height: 21px;
}
.footer-link2:hover {
  color: rgba(196, 196, 196, 0.5);
}
.footer-link3 {
  color: rgb(196, 196, 196);
  cursor: pointer;
  font-size: 14px;
  transition: 0.3s;
  line-height: 21px;
}
.footer-link3:hover {
  color: rgba(196, 196, 196, 0.5);
}
.footer-link4 {
  color: rgb(196, 196, 196);
  cursor: pointer;
  font-size: 14px;
  transition: 0.3s;
  line-height: 21px;
}
.footer-link4:hover {
  color: rgba(196, 196, 196, 0.5);
}
.footer-link5 {
  color: rgb(196, 196, 196);
  cursor: pointer;
  font-size: 14px;
  transition: 0.3s;
  line-height: 21px;
}
.footer-link5:hover {
  color: rgba(196, 196, 196, 0.5);
}
.footer-text1 {
  color: #C4C4C4;
  width: 100%;
  font-size: 14px;
  max-width: 1200px;
  line-height: 21px;
}
@media(max-width: 991px) {
  .footer-footer {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
}
@media(max-width: 767px) {
  .footer-footer {
    gap: var(--dl-space-space-twounits);
  }
  .footer-content {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .footer-information {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .footer-heading {
    gap: var(--dl-space-space-halfunit);
  }
  .footer-pasted-image {
    width: 100px;
  }
  .footer-text {
    font-size: 12px;
    line-height: 18px;
  }
  .footer-socials {
    gap: var(--dl-space-space-halfunit);
  }
  .footer-links {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    flex-direction: column;
  }
  .footer-header {
    font-size: 16px;
    line-height: 24px;
  }
}
@media(max-width: 479px) {
  .footer-links {
    width: 100%;
    flex-direction: column;
  }
}
