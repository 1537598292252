
  .home-container {
    padding-top:20px;
  margin-top:10px;
  width: 100%;
  display: flex;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
}
.home-header {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
  height: 100vh;
 
}
.home-heading {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  margin-bottom:40px;
}
.home-notification {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
}
.home-hero {
  width: 100%;

  display: flex;
  position: relative;
  max-width: 1200px;
  align-items: start;
 
  padding-left: var(--dl-space-space-oneandhalfunits);

  flex-direction: row;
  justify-content: space-between;

  background-image: url("../../../../public/1new.png");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: top;
  background-size:600px;
 
  
}
.home-content {
  gap: var(--dl-space-space-oneandhalfunits);
  
  display: flex;
  z-index: 20;
  align-items: start;

  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}

.home-title {
  font-size:56px;
  font-style: normal;
  font-family: Poppins;
  width:700px;
  
  font-weight: 600;
  line-height: 76px;
  animation-name: none;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.home-caption {
  line-height: 27px;
  width: 100%;
  
}
.home-hero-buttons {
  gap: var(--dl-space-space-unit);
  display: flex;
  z-index: 15;
  margin-top: var(--dl-space-space-unit);
  align-items: left;
  flex-direction: row;
  justify-content: center;
}
.home-link {
  display: contents;
}
.home-ios-btn {
  gap: var(--dl-space-space-halfunit);
  fill: white;
  color: white;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-radius:7px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  text-decoration: none;
  background-color: #000000;
}
.home-ios-btn:hover {
  background-color: rgba(0, 0, 0, 0.75);
}
.home-icon {
  width: 24px;
  height: 24px;
}
.home-caption1 {
  font-family: "Poppins";
}
.home-link1 {
  display: contents;
}
.home-android-btn {
  gap: var(--dl-space-space-halfunit);
  fill: white;
  color: white;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  text-decoration: none;
  background-color: #000000;
}
.home-android-btn:hover {
  background-color: rgba(0, 0, 0, 0.75);
}
.home-icon02 {
  width: 24px;
  height: 24px;
}
.home-caption2 {
  font-family: "Poppins";
}


.home-column {
  gap: var(--dl-space-space-oneandhalfunits);
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-pasted-image {
  width: 240px;
}
.home-column1 {
  gap: var(--dl-space-space-oneandhalfunits);
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-pasted-image1 {
  width: 240px;
}
.home-pasted-image2 {
  width: 240px;
}
.home-column2 {
  gap: var(--dl-space-space-oneandhalfunits);
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-pasted-image3 {
  width: 240px;
}
.home-pasted-image4 {
  width: 240px;
}

.home-video {
  color: white;
  width: 100%;
  height: 241px;
  
  display: flex;
  margin-bottom:50px;
  z-index: 10;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}
.home-stats {
  margin-bottom:50px;
  width: 100%;
  display: flex;
  z-index: 10;
  max-width: 1200px;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: center;
  background-color: #ffffff;
}
.home-stat {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  /* border-color: #DBDBDB; */
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  /* border-right-width: 1px;
  border-bottom-width: 1px; */
}
.home-caption3 {
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 56px;
}
.home-description {
  width: 100%;
  font-size: 18px;
  max-width: 350px;
  text-align: center;
  font-family: Poppins;
  line-height: 27px;
}
.home-stat1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  /* border-color: #DBDBDB; */

  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  /* border-bottom-width: 1px; */
}
.home-caption4 {
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 56px;
}
.home-description1 {
  width: 100%;
  font-size: 18px;
  max-width: 350px;
  text-align: center;
  font-family: Poppins;
  line-height: 27px;
}
.home-stat2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  /* border-color: #DBDBDB; */
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  /* border-left-width: 1px;
  border-bottom-width: 1px; */
}
.home-caption5 {
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 56px;
}
.home-description2 {
  width: 100%;
  font-size: 18px;
  max-width: 350px;
  text-align: center;
  font-family: Poppins;
  line-height: 27px;
}
.home-sections {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
}
.home-section {
  gap: var(--dl-space-space-sixunits);
  width: 100%;
  display: flex;
  z-index: 10;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.home-image {
  width: 400px;
  height: 590px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  background-size: cover;
  justify-content: center;
  background-image: url("../../../../public/2.png");
  background-repeat: no-repeat;
  background-position: center;
}
/* .home-image-overlay {
  right: 0px;
  width: 180px;
  bottom: 0px;
  height: 320px;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: row;
  background-size: cover;
  justify-content: center;
background-image: url("../../../../public/2.png"); 
} */
.home-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  margin-top: var(--dl-space-space-fiveunits);
  align-items: flex-start;
  flex-direction: column;
}
.home-text06 {
  font-size: 40px;
  max-width: 640px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 56px;
}
.home-section1 {
  gap: var(--dl-space-space-sixunits);
  width: 100%;
  display: flex;
  z-index: 10;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  flex-direction: row;
  justify-content: center;
}
.home-content2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-heading01 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text09 {
  font-size: 40px;
  max-width: 535px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 56px;
}
.home-text10 {
  font-size: 18px;
  max-width: 535px;
  font-family: Poppins;
  line-height: 27px;
}
.home-content3 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-points {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-navlink {
  display: contents;
}
.home-get-started {
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  text-decoration: none;
  background-color: #ADE2DF;
}
.home-get-started:hover {
  background-color: rgba(173, 226, 223, 0.5);
}
.home-sign-up {
  font-style: normal;
  font-weight: 500;
}
.home-image1 {
  width: 370px;
  height: 600px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  background-size: cover;
  justify-content: flex-end;
  background-image: url("../../../../public/3.png");
  background-repeat: no-repeat;
  background-position: center;
}
.home-section2 {
  gap: var(--dl-space-space-sixunits);
  width: 100%;
  display: flex;
  z-index: 10;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  flex-direction: row;
  justify-content: center;
  background-color: #ffffff;
}
.home-image2 {
  width: 400px;
  height: 540px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  background-size: contain;
  justify-content: flex-end;
  background-image: url("../../../../public/4.png");
  background-repeat: no-repeat;
  background-position: center;
 
}

.home-text11 {
  color: rgb(193, 255, 252);
  /* width: 100%; */
  /* max-width: 90px; */
  font-style: normal;
  text-align: center;
  font-family: Poppins;
  font-weight: 500;
}
.home-content4 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text14 {
  font-size: 40px;
  max-width: 535px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 56px;
}
.home-explore {
  cursor: pointer;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  align-items: flex-start;
  border-width: 0px;
  flex-direction: column;
  padding-bottom: 4px;
  border-bottom-width: 1px;
}
.home-explore:hover {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.3);
}
.home-banner-container {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
.home-banner {
  width: 100%;
  height: 360px;
  display: flex;
  overflow: hidden;
  max-width: 1200px;
  align-items: center;
  border-radius: 16px;
  flex-direction: column;
  justify-content: space-around;
  flex-direction: row;
  background-color: #375EE0;
  padding-top:60px;
  padding-right:20px;
  /* gap:20%; */
}

.home-book-btn {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  text-decoration: none;
  background-color: #000000;
}
.home-book-btn:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.home-text17 {
  color: #ffffff;
  width: 100%;
  max-width: 490px;
  font-style: normal;
  font-weight: 500;
}
.home-text18 {
  color: rgb(193, 255, 252);
  width: 100%;
  font-size: 40px;
  max-width: 490px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.home-book-btn1 {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  text-decoration: none;
  background-color: #000000;
}
.home-book-btn1:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.home-text19 {
  color: #ffffff;
  width: 100%;
  max-width: 490px;
  font-style: normal;
  font-weight: 500;
}
.home-features {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 16px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: flex-start;
}
.home-header1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-tag {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: #D6F1F0;
}
.home-text20 {
  font-style: normal;
  font-weight: 500;
}
.home-heading02 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text21 {
  width: 100%;
  font-size: 40px;
  max-width: 400px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
}
.home-text22 {
  width: 100%;
  font-size: 18px;
  max-width: 600px;
  line-height: 27px;
}
.home-feature-list {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-pricing {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 16px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  background-color: #F3F5FA;
}
.home-content5 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
}
.home-header2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-tag1 {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: #D6F1F0;
}
.home-text23 {
  font-style: normal;
  font-weight: 500;
}
.home-heading03 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text24 {
  width: 100%;
  font-size: 40px;
  max-width: 600px;
  font-style: normal;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: 56px;
}
.home-pricing-plans {
  flex: 1;
  width: 100%;
  display: grid;
  overflow: hidden;
  align-items: flex-start;
  border-color: #DBDBDB;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #375EE0;
  grid-template-columns: auto auto;
}
.home-plans {
  flex: 1;
  height: 100%;
  display: grid;
  padding-top: var(--dl-space-space-twounits);
  padding-left: TwoUnits;
  padding-bottom: var(--dl-space-space-twounits);
  background-color: #ffffff;
  grid-template-columns: auto auto auto;
}
.home-plan {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 300px;
  align-items: flex-start;
  border-color: #DBDBDB;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: flex-start;
  border-right-width: 1px;
}
.home-top {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  border-color: #DBDBDB;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: flex-start;
  border-bottom-width: 1px;
}
.home-heading04 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text25 {
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.home-text26 {
  font-family: Poppins;
  line-height: 24px;
}
.home-cost {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text27 {
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 40px;
}
.home-bottom {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}
.home-check-list {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
}
.home-button {
  width: 100%;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #ADE2DF;
}
.home-button:hover {
  background-color: rgba(173, 226, 223, 0.5);
}
.home-text28 {
  font-style: normal;
  font-weight: 500;
}
.home-plan1 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 300px;
  align-items: flex-start;
  border-color: #DBDBDB;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
  border-right-width: 1px;
}
.home-top1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  border-color: #DBDBDB;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: flex-start;
  border-bottom-width: 1px;
}
.home-heading05 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text29 {
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.home-text30 {
  font-family: Poppins;
  line-height: 24px;
}
.home-cost1 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-start;
}
.home-text31 {
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 40px;
}
.home-text32 {
  font-family: Poppins;
}
.home-bottom1 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}
.home-check-list1 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
}
.home-button1 {
  width: 100%;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #ADE2DF;
}
.home-button1:hover {
  background-color: rgba(173, 226, 223, 0.5);
}
.home-text33 {
  font-style: normal;
  font-weight: 500;
}
.home-plan2 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 300px;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}
.home-top2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  border-color: #DBDBDB;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: flex-start;
  border-bottom-width: 1px;
}
.home-heading06 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text34 {
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.home-text35 {
  font-family: Poppins;
  line-height: 24px;
}
.home-cost2 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-start;
}
.home-text36 {
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 40px;
}
.home-text37 {
  font-family: Poppins;
}
.home-bottom2 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}
.home-check-list2 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
}
.home-button2 {
  width: 100%;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #ADE2DF;
}
.home-button2:hover {
  background-color: rgba(173, 226, 223, 0.5);
}
.home-text38 {
  font-style: normal;
  font-weight: 500;
}
.home-expand {
  flex: 0.3;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.home-overlay {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: space-between;
  background-image: url("../../../../public/planbackground.svg");
}
.home-header3 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-heading07 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 256px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text39 {
  color: #ffffff;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.home-text40 {
  color: #C1FFFC;
  font-size: 24px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 31px;
}
.home-check-list3 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-check {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-icon04 {
  fill: #ffffff;
  width: 18px;
  height: 18px;
}
.home-text41 {
  color: #ffffff;
  font-size: 14px;
  font-family: Poppins;
  line-height: 24px;
}
.home-check1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-icon06 {
  fill: #ffffff;
  width: 18px;
  height: 18px;
}
.home-text42 {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-family: Poppins;
  line-height: 24px;
}
.home-check2 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-icon08 {
  fill: #ffffff;
  width: 18px;
  height: 18px;
}
.home-text43 {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-family: Poppins;
  line-height: 24px;
}
.home-button3 {
  width: 100%;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #000000;
}
.home-button3:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.home-text44 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 500;
}
.home-plans1 {
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  flex-direction: column;
}
.home-plan3 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 300px;
  align-items: flex-start;
  border-color: #DBDBDB;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: flex-start;
  border-right-width: 1px;
}
.home-top3 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  border-color: #DBDBDB;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: flex-start;
  border-bottom-width: 1px;
}
.home-heading08 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text47 {
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.home-text48 {
  font-family: Poppins;
  line-height: 24px;
}
.home-cost3 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text49 {
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 40px;
}
.home-bottom3 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}
.home-check-list4 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
}
.home-button4 {
  width: 100%;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #ADE2DF;
}
.home-button4:hover {
  background-color: rgba(173, 226, 223, 0.5);
}
.home-text50 {
  font-style: normal;
  font-weight: 500;
}
.home-plan4 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 300px;
  align-items: flex-start;
  border-color: #DBDBDB;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
  border-right-width: 1px;
}
.home-top4 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  border-color: #DBDBDB;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: flex-start;
  border-bottom-width: 1px;
}
.home-heading09 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text51 {
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.home-text52 {
  font-family: Poppins;
  line-height: 24px;
}
.home-cost4 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-start;
}
.home-text53 {
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 40px;
}
.home-text54 {
  font-family: Poppins;
}
.home-bottom4 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}
.home-check-list5 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
}
.home-button5 {
  width: 100%;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #ADE2DF;
}
.home-button5:hover {
  background-color: rgba(173, 226, 223, 0.5);
}
.home-text55 {
  font-style: normal;
  font-weight: 500;
}
.home-plan5 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 300px;
  align-items: flex-start;
  border-color: #DBDBDB;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
  border-right-width: 1px;
}
.home-top5 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  border-color: #DBDBDB;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: flex-start;
  border-bottom-width: 1px;
}
.home-heading10 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text56 {
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.home-text57 {
  font-family: Poppins;
  line-height: 24px;
}
.home-cost5 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-start;
}
.home-text58 {
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 40px;
}
.home-text59 {
  font-family: Poppins;
}
.home-bottom5 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}
.home-check-list6 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
}
.home-button6 {
  width: 100%;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #ADE2DF;
}
.home-button6:hover {
  background-color: rgba(173, 226, 223, 0.5);
}
.home-text60 {
  font-style: normal;
  font-weight: 500;
}
.home-expand1 {
  flex: 0.3;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.home-overlay1 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: space-between;
  background-image: url("../../../../public/planbackground.svg");
}
.home-header4 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-heading11 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 256px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text61 {
  color: #ffffff;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.home-text62 {
  color: #C1FFFC;
  font-size: 24px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 31px;
}
.home-check-list7 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-check3 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-icon10 {
  fill: #ffffff;
  width: 18px;
  height: 18px;
}
.home-text63 {
  color: #ffffff;
  font-size: 14px;
  font-family: Poppins;
  line-height: 24px;
}
.home-check4 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-icon12 {
  fill: #ffffff;
  width: 18px;
  height: 18px;
}
.home-text64 {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-family: Poppins;
  line-height: 24px;
}
.home-check5 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-icon14 {
  fill: #ffffff;
  width: 18px;
  height: 18px;
}
.home-text65 {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-family: Poppins;
  line-height: 24px;
}
.home-button7 {
  width: 100%;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #000000;
}
.home-button7:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.home-text66 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 500;
}
.home-help {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  flex-direction: row;
}
.home-text69 {
  font-family: Poppins;
}
.home-explore1 {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  border-width: 0px;
  flex-direction: column;
  padding-bottom: 4px;
  border-bottom-width: 1px;
}
.home-explore1:hover {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.3);
}
.home-text70 {
  font-style: normal;
  font-weight: 600;
}
.home-get-started1 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  background-color: #375EE0;
}
.home-content6 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
}
.home-heading12 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text71 {
  color: #C1FFFC;
  font-size: 64px;
  font-style: normal;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
}
.home-text72 {
  color: #ffffff;
  font-size: 20px;
  text-align: center;
  font-family: Poppins;
  line-height: 30px;
}
.home-hero-buttons1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  z-index: 15;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-link2 {
  display: contents;
}
.home-ios-btn1 {
  gap: var(--dl-space-space-halfunit);
  fill: white;
  color: white;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  text-decoration: none;
  background-color: #000000;
}
.home-ios-btn1:hover {
  background-color: rgba(0, 0, 0, 0.75);
}
.home-icon16 {
  width: 24px;
  height: 24px;
}
.home-caption6 {
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.home-link3 {
  display: contents;
}
.home-android-btn1 {
  gap: var(--dl-space-space-halfunit);
  fill: white;
  color: white;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  text-decoration: none;
  background-color: #000000;
}
.home-android-btn1:hover {
  background-color: rgba(0, 0, 0, 0.75);
}
.home-icon18 {
  width: 24px;
  height: 24px;
}
.home-caption7 {
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
@media(max-width: 1600px) {
  .home-caption3 {
    font-size: 40px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    line-height: 56px;
  }
  .home-description {
    font-size: 18px;
    font-family: Poppins;
    line-height: 27px;
  }
  .home-caption4 {
    font-size: 40px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    line-height: 56px;
  }
  .home-description1 {
    line-height: 27px;
  }
  .home-caption5 {
    font-size: 40px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    line-height: 56px;
  }
  .home-description2 {
    font-size: 18px;
    font-family: Poppins;
    line-height: 27px;
  }
  .home-text06 {
    line-height: 56px;
  }
  .home-text09 {
    font-size: 40px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    line-height: 56px;
  }
  .home-text10 {
    font-size: 18px;
    font-family: Poppins;
    line-height: 27px;
  }
  .home-text14 {
    font-size: 40px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    line-height: 56px;
  }
  .home-banner-container {
    height: 282px;
  }
  .home-pasted-image5 {
    display: none;
  }
  .home-text16 {
    color: rgb(193, 255, 252);
  }
  .home-text18 {
    color: rgb(193, 255, 252);
    font-size: 40px;
    align-self: flex-start;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    padding-left: var(--dl-space-space-threeunits);
  }
  .home-book-btn1 {
    align-self: flex-start;
    margin-top: var(--dl-space-space-unit);
    margin-left: var(--dl-space-space-fiveunits);
    padding-left: var(--dl-space-space-twounits);
  }
  .home-text21 {
    line-height: 56px;
  }
  .home-text22 {
    font-size: 18px;
    line-height: 27px;
  }
  .home-pricing {
    display: none;
  }
  .home-text71 {
    color: rgb(193, 255, 252);
    font-size: 64px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
  }
  .home-text72 {
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-family: Poppins;
    line-height: 30px;
  }
  .home-caption6 {
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
  }
  .home-caption7 {
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
  }
  .home-caption{
    width:660px;
  }
}
@media(max-width: 1200px) {
  .home-image-highlight {
    top: 0px;
    left: -210px;
  }
  .home-caption{
    width:660px;
  }
}
@media(max-width: 1120px) and (min-width:991px)  {


  .home-title {
 /* min-width:450px; */
 width:60%;
  }
  .home-hero{
    background-size:480px;}
    .home-caption {
      /* line-height: 27px; */
      width:60%;
    }

}



@media(max-width: 991px) {
  
  .home-title {
    font-size:46px;
    width:40%;
     }
     .home-hero{
       background-position-y:25px;
       background-size:45%;}
       
       .home-caption {
        
         width:50%;
       }
  .home-content {
    gap: var(--dl-space-space-unit);
    flex: 0 0 auto;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: 0px;
  }

  .home-hero-buttons {
    width:35%;
    font-size:15px;
    display: flex;
    flex-direction: column;    
  }
  .home-ios-btn {
    flex: 1;
  
    }
    .home-android-btn {
   
    flex: 1;
  }
  .home-images {
    top:-265px;
    /* margin: auto; */
    margin-top:115px;
  }
 
  .home-stats {
    padding-top: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-stat {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
    padding-bottom: var(--dl-space-space-threeunits);
    border-right-width: 0px;
  }
  .home-caption3 {
    text-align: center;
    line-height: 32px;
  }
  .home-stat1 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-caption4 {
    text-align: center;
    line-height: 32px;
  }
  .home-stat2 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
    border-left-width: 0px;
  }
  .home-caption5 {
    text-align: center;
    line-height: 32px;
  }
  .home-section {
    align-items: center;
    padding-top: var(--dl-space-space-fourunits);
    border-color: #DBDBDB;
    flex-direction: column-reverse;
    padding-bottom: var(--dl-space-space-fourunits);
    border-top-width: 1px;
  }
  .home-content1 {
    margin-top: 0px;
  }
  .home-section1 {
    padding-top: var(--dl-space-space-fourunits);
    border-color: #DBDBDB;
    flex-direction: column;
    border-top-width: 1px;
  }
  .home-content2 {
    gap: var(--dl-space-space-unit);
  }
  .home-content3 {
    gap: var(--dl-space-space-twounits);
  }
  .home-section2 {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-fourunits);
    flex-direction: column-reverse;
  }
  .home-content4 {
    gap: var(--dl-space-space-unit);
  }
  .home-explore {
    margin-top: var(--dl-space-space-halfunit);
  }
  .home-pricing {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-content5 {
    gap: var(--dl-space-space-threeunits);
  }
  .home-header2 {
    gap: var(--dl-space-space-unit);
  }
  .home-pricing-plans {
    grid-template-columns: none;
  }
  .home-plans {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-overlay {
    width: 100%;
    background-size: contain;
    justify-content: space-between;
    background-repeat: no-repeat;
    background-position: right;
  }
  .home-header3 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-heading07 {
    gap: var(--dl-space-space-halfunit);
    max-width: 600px;
  }
  .home-text39 {
    font-size: 18px;
  }
  .home-text40 {
    font-size: 20px;
    line-height: 26px;
  }
  .home-text41 {
    line-height: 21px;
  }
  .home-text42 {
    line-height: 21px;
  }
  .home-text43 {
    line-height: 21px;
  }
  .home-button3 {
    margin-top: var(--dl-space-space-threeunits);
  }
  .home-overlay1 {
    width: 100%;
    justify-content: space-between;
  }
  .home-header4 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-heading11 {
    gap: var(--dl-space-space-halfunit);
    max-width: 600px;
  }
  .home-text61 {
    font-size: 18px;
  }
  .home-text62 {
    font-size: 20px;
    line-height: 26px;
  }
  .home-text63 {
    line-height: 21px;
  }
  .home-text64 {
    line-height: 21px;
  }
  .home-text65 {
    line-height: 21px;
  }
  .home-button7 {
    margin-top: var(--dl-space-space-threeunits);
  }
  .home-get-started1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-content6 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-heading12 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-text71 {
    font-size: 40px;
  }
  .home-text72 {
    font-size: 18px;
  }
}
@media(max-width: 767px) {
  .home-title {
    /* width: */
    width:400px;
    font-size:40px;
    font-weight:bolder;
    line-height:65px;
    opacity:1;
  }
  .home-caption {
    font-size: 16px;
    line-height: 24px;
  }
  .home-ios-btn{
    font-size:12px;
    padding:20px 15px;
    }
    .home-android-btn{
    font-size:12px;
    padding:20px 15px;

  }

  .home-images{
    bottom:-315px;
    opacity:.7;
  }
  
  .home-pasted-image {
    width: 180px;
  }
  .home-pasted-image1 {
    width: 180px;
  }
  .home-pasted-image2 {
    width: 180px;
  }
  .home-pasted-image3 {
    width: 180px;
  }
  .home-pasted-image4 {
    width: 180px;
  }
  
  .home-caption3 {
    font-size: 32px;
  }
  .home-description {
    font-size: 16px;
    line-height: 24px;
  }
  .home-caption4 {
    font-size: 32px;
  }
  .home-description1 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-caption5 {
    font-size: 32px;
  }
  .home-description2 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-section {
    gap: var(--dl-space-space-twounits);
  }
  .home-text09 {
    font-size: 32px;
    line-height: 44px;
  }
  .home-text10 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-get-started {
    display: none;
  }
  .home-text14 {
    font-size: 32px;
    line-height: 44px;
  }
  .home-banner {
    height: 520px;
    position: relative;
  }
  .home-pasted-image5 {
    right: 0px;
    bottom: 0px;
    height: 300px;
    display: flex;
    position: absolute;
    align-self: flex-end;
  }

  
  .home-text16 {
    font-size: 32px;
  }
  .home-text18 {
    font-size: 32px;
  }
  .home-features {
    gap: var(--dl-space-space-threeunits);
  }
  .home-header1 {
    gap: var(--dl-space-space-unit);
  }
  .home-text20 {
    font-size: 14px;
  }
  .home-text21 {
    font-size: 32px;
    line-height: 44px;
  }

  .home-text22 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-feature-list {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    flex-direction: column;
  }
  .home-text24 {
    font-size: 32px;
    line-height: 44px;
  }
  .home-pricing-plans {
    display: none;
  }
  .home-plans1 {
    gap: var(--dl-space-space-oneandhalfunits);
    display: flex;
  }
  .home-plan3 {
    max-width: 100%;
    padding-top: var(--dl-space-space-threeunits);
    border-width: 1px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    border-radius: 16px;
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
    background-color: #ffffff;
  }
  .home-top3 {
    width: 100%;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-heading08 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-text47 {
    font-size: 18px;
  }
  .home-text49 {
    font-size: 32px;
    line-height: 32px;
  }
  .home-plan4 {
    max-width: 100%;
    padding-top: var(--dl-space-space-threeunits);
    border-width: 1px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    border-radius: 16px;
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
    background-color: #ffffff;
    border-right-width: 1px;
  }
  .home-top4 {
    width: 100%;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-heading09 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-text51 {
    font-size: 18px;
  }
  .home-text53 {
    font-size: 32px;
    line-height: 32px;
  }
  .home-text55 {
    font-weight: 500;
  }
  .home-plan5 {
    max-width: 100%;
    padding-top: var(--dl-space-space-threeunits);
    border-width: 1px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    border-radius: 16px;
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
    background-color: #ffffff;
    border-right-width: 1px;
  }
  .home-top5 {
    width: 100%;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-heading10 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-text56 {
    font-size: 18px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
  }
  .home-text58 {
    font-size: 32px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    line-height: 32px;
  }
  .home-text60 {
    font-weight: 500;
  }
  .home-expand1 {
    flex: 0 0 auto;
    width: 100%;
    border-radius: 16px;
    background-color: #375EE0;
  }
  .home-overlay1 {
    align-items: center;
    background-size: contain;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: top right;
  }
  .home-get-started1 {
    display: none;
  }
}
@media(max-width:630px){
  .home-title{
    font-size:32px;
  }
  .home-hero{
    background-position-y:30px;
    background-size:200px;
  }
}
@media(max-width: 479px) {

  .home-title{
    font-size:48px;
    line-height:68px;
    width:100%;

  }
  
  .home-hero-buttons {
    
    font-size:12px;
    width:40%;
    flex-direction: column;
  }
  .home-ios-btn {
    width: 100%;
    padding:15px 5px;
    min-width: 130px;
    }
    .home-android-btn {
      min-width: 130px;
    padding:15px 5px;
    width: 100%;
  }
  .home-images {
    bottom: -375px;
    opacity: 1;
  }
  .home-pasted-image {
    width: 150px;
  }
  .home-pasted-image1 {
    width: 150px;
  }
  .home-pasted-image2 {
    width: 150px;
  }
  .home-pasted-image3 {
    width: 150px;
  }
  .home-pasted-image4 {
    width: 150px;
  }
  .home-text06{
    font-size:34px;
    font-weight:bolder;
  }
  .home-image {
    width: 100%;
    max-width: 400px;
  }
  .home-image2 {
    width: 100%;
    max-width: 400px;
  }
  .home-pasted-image5 {
    height: 225px;
  }
  .home-help {
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .home-hero-buttons1 {
    width: 100%;
    flex-direction: column;
  }
  .home-ios-btn1 {
    width: 100%;
  }
  .home-android-btn1 {
    width: 100%;
  }
  .home-banner{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-bottom:50px;
     */
     justify-content: space-around;
     }
     .home-text18 {
       
       font-size:23px;
       }
       .home-book-btn1{
         
         font-size:10px;
        min-width:130px;

  }

}
